<template>
  <div class="place-page" v-if="placeData">

    <h1>Visits of "{{ placeData.title }}"</h1>

    <div class="table-responsive">
      <table class="table">
        <thead class="table-light small">
        <tr>
          <th width="1%">ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Passport</th>
          <th class="text-center">Amount/Payed</th>
          <th>Status</th>
          <th width="1%" class="text-nowrap text-center">Date start</th>
          <th width="1%" class="text-nowrap text-center">Date finish</th>
          <th width="1%" class="text-nowrap text-center">Date create</th>
          <th width="1%"></th>
        </tr>
        </thead>
        <tbody v-if="orderList?.length > 0">
        <tr v-for="orderData in orderList" :key="orderData.id">
          <td class="text-nowrap">{{ orderData.id }}</td>
          <td>
              <span v-if="orderData.userData" class="small">
                {{ orderData.userData.name }}
              </span>
          </td>
          <td>
              <span v-if="orderData.userData" class="small">
                {{ orderData.userData.email }}
              </span>
          </td>
          <td>
              <span v-if="orderData.userData" class="small">
                {{ orderData.userData.phone }}
              </span>
          </td>
          <td>
              <span v-if="orderData.userData" class="small">
                {{ orderData.userData.passport }}
              </span>
          </td>
          <td class="text-center">
            {{ this.getPrice(orderData.amount) }}{{ this.getCurrencySymbol() }} /
            {{ this.getPrice(orderData.payAmount) }}{{ this.getCurrencySymbol() }}
          </td>
          <td>
              <span class="badge bg-secondary">
                {{ orderStatusEnum.getLabels()[orderData.status] }}
              </span>
          </td>
          <td class="text-nowrap text-center small">
            <span v-if="orderData.dateStart">{{ this.getDateTime(orderData.dateStart) }}</span>
          </td>
          <td class="text-nowrap text-center small">
            <span v-if="orderData.dateFinish">{{ this.getDateTime(orderData.dateFinish) }}</span>
          </td>
          <td class="text-nowrap text-center small">
            {{ this.getDateTime(orderData.dateCreate) }}
          </td>
          <td class="text-nowrap">
            <a href="#" @click.prevent="doOrderPartnerClose(orderData.id)" class="fa fa-check-square icon-fa" v-if="orderData.status == orderStatusEnum.open || orderData.status == orderStatusEnum.stop"></a>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="100%" class="text-center">No records found</td>
        </tr>
        </tbody>
      </table>
    </div>

    <pager-nav :pager="orderPager" :route="'/place/partner/view/'" @onPagerUpdate="onPagerUpdate"></pager-nav>

  </div>
</template>

<script>
import PagerNav from "@/components/PagerNav";
import {PlaceStatusEnum} from "@/modules/place/enums/PlaceEnum";
import {OrderStatusEnum} from "@/modules/order/enums/OrderEnum";

export default {
  components: {PagerNav},

  data: () => ({
    placeData: {},
    orderList: [],
    orderPager: {},
    placeStatusEnum: PlaceStatusEnum,
    orderStatusEnum: OrderStatusEnum
  }),

  async mounted() {

    if (!await this.checkPartner())
      return;

    await this.doPlacePartnerView();

    this.setBreadcrumbs();
  },

  methods: {

    async onPagerUpdate() {

      await this.doPlacePartnerView();
    },

    async doPlacePartnerView() {

      let apiHolder = await this.sendApiGet(['api/place/partner/view/' + this.$route.params?.id]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeData)
        this.placeData = apiHolder.data.placeData;
      if (apiHolder.data?.orderList)
        this.orderList = apiHolder.data.orderList;
      if (apiHolder.data?.orderPager)
        this.orderPager = apiHolder.data.orderPager;
    },

    async doOrderPartnerClose(orderId) {

      let apiHolder = await this.sendApiPost(['api/order/partner/status/close/' + orderId]);

      if (!apiHolder.isSuccess())
        return;

      this.orderList = this.orderList.map((orderData) => {

        if (orderData.id == apiHolder.data?.orderData?.id)
          return apiHolder.data.orderData;

        return orderData;
      });
    },

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        ['Partner panel', '/partner'],
        ['My gym', '/place/partner'],
        this.placeData?.title
      ]);
    }

  }

}
</script>

<style scoped>

</style>